import { Box, useMediaQuery, useTheme, Fab } from "@mui/material"
import ChatBubbleIcon from "@mui/icons-material/ChatBubble"
import Header from "./Header"
import TextUsDialog from "components/TextUsDialog"
import React from "react"
import Footer from "./Footer"

export interface LayoutProps {
  children?: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [isOpen, setIsOpen] = React.useState(false)
  const headerHeight = isMobile ? "56px" : "64px"

  return (
    <>
      <Header />
      <Box
        sx={{
          mt: headerHeight,
          minHeight: `calc(100vh - ${headerHeight})`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        {children}
        <Fab
          color="secondary"
          onClick={() => setIsOpen(true)}
          sx={{
            color: "secondary.contrastText",
            position: "fixed",
            bottom: theme.spacing(5),
            right: theme.spacing(5),
          }}
          aria-label="Chat with us"
        >
          <ChatBubbleIcon />
        </Fab>
      </Box>
      <Footer />
      <TextUsDialog open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}
