import {
  PhoneIphone,
  TagFaces,
  LooksOne,
  TransferWithinAStation,
  CheckCircle,
  DateRange,
  DirectionsCar,
  School,
  ChatBubble,
  Face,
  Videocam,
} from "@mui/icons-material"

export const NUM_FIVE_STAR_REVIEWS = "86,000"
export const NUM_STUDENTS = "125,000"

export const IMAGES = [
  "sparklers_zmoqdn",
  "happy-woods-square_ewzr1w",
  "happy-friends-smaller-square_iwuo5g",
  "happy-driver-smaller-square_kx7sn9",
]

export const features = {
  generic: [
    {
      head: "Certified driving instructors",
      text: "Every instructor is certified and licensed and has completed an intensive training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Online booking, secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Free pickup and dropoff in most states",
      text: "We come to your house, school or work—and can even drop you off at a convenient location within 5-10 minutes of your pickup address, or meet in convenient public locations",
      icon: <TransferWithinAStation />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands during your driving lessons—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—our instructors customize every lesson to your precise needs",
      icon: <LooksOne />,
    },
    {
      head: "Transparent availability",
      text: "Our online booking system shows you all available time slots, so you can book packages knowing when we're available",
      icon: <DateRange />,
    },
    {
      head: "Driving Test coaching",
      text: "We teach you the skills you need to be a great driver, and also prepare you for your road test, so you can test with confidence",
      icon: <DirectionsCar />,
    },
    {
      head: "Dedicated support team",
      text: "Our highly trained customer support staff can answer any question—from scheduling help to the licensing process, we're here for you",
      icon: <ChatBubble />,
    },
  ],
  ca: [
    {
      head: "Free pickup and dropoff",
      text: "We come to your house, school or work—and can even drop you off at a convenient location within 5-10 minutes of your pickup address",
      icon: <TransferWithinAStation />,
    },
    {
      head: "DMV-certified driving instructors",
      text: "Every instructor is certified and licensed by the DMV and has completed an intensive training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Online booking, secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Transparent availability",
      text: "Our online booking system shows you all available time slots, so you can book packages knowing when we're available",
      icon: <DateRange />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—our instructors customize every lesson to your precise needs",
      icon: <LooksOne />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands or make students pick up and drop off other students—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "DMV Test coaching",
      text: "We teach you the skills you need to be a great driver, and also prepare you for your road test, so you can test with confidence",
      icon: <DirectionsCar />,
    },
  ],
  co: [
    {
      head: "Free pickup and dropoff",
      text: "We meet you at your house, school or work - no need to come to our office",
      icon: <TransferWithinAStation />,
    },
    {
      head: "State-certified driving instructors",
      text: "Every instructor is certified and licensed by the Colorado DMV and has completed an intensive training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Online booking, secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands or make students pick up and drop off other students—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—our instructors customize every lesson to your precise needs",
      icon: <LooksOne />,
    },
    {
      head: "Transparent availability",
      text: "Our online booking system shows you all available time slots, so you can book packages knowing when we're available",
      icon: <TransferWithinAStation />,
    },
    {
      head: "Largest driving school in the country",
      text: "We have taught tens of thousands of lessons across the United States, and our local Colorado instructors are the best in the business",
      icon: <DirectionsCar />,
    },
  ],
  il: [
    {
      head: "Certified driving instructors",
      text: "Every instructor is certified and licensed and has completed an intensive training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Online booking, secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Premium classroom experience",
      text: "Our classroom course is taught by experienced driving instructors who are committed to an engaging classroom experience",
      icon: <TransferWithinAStation />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands or make students pick up and drop off other students—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—our instructors customize every lesson to your precise needs",
      icon: <LooksOne />,
    },
    {
      head: "Transparent availability",
      text: "Our online booking system shows you all available time slots, so you can book packages knowing when we're available",
      icon: <DateRange />,
    },
    {
      head: "Driving Test coaching",
      text: "We teach you the skills you need to be a great driver, and also prepare you for your road test, so you can test with confidence",
      icon: <DirectionsCar />,
    },
    {
      head: "Take your Driving Test with Us",
      text: "Skip the long waits at the DMV and take your driving test with a Coastline instructor in a Coastline vehicle.",
      icon: <DirectionsCar />,
    },
  ],
  in: [
    {
      head: "BMV-certified driving instructors",
      text: "Every instructor is certified and licensed by the BMV and has completed an intensive training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Online booking, secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands or make students pick up and drop off other students—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—every lesson is customized to your precise needs",
      icon: <LooksOne />,
    },
    {
      head: "BMV Test coaching",
      text: "We teach you the skills you need to be a great driver, and also prepare you for your road test, so you can test with confidence",
      icon: <DirectionsCar />,
    },
    {
      head: "Post-Lesson Report Included",
      text: "At the end of your lesson, your instructor fills out a post-lesson report that shows the skills you have mastered, and those that need more practice, so you can maximize your practice time between lessons.",
      icon: <School />,
    },
  ],
  mo: [
    {
      head: "Highly trained driving instructors",
      text: "Every instructor has completed an intensive, nation-leading training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands or make students pick up and drop off other students—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—our instructors customize every lesson to your precise needs",
      icon: <LooksOne />,
    },
    {
      head: "Safe and confident for life",
      text: "We teach you the skills you need to be a great driver in the real world - our mission is to eradicate car crashes, and that starts with how we work with you",
      icon: <DirectionsCar />,
    },
  ],
  nv: [
    {
      head: "Highly trained driving instructors",
      text: "Every instructor has completed an intensive, nation-leading training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands or make students pick up and drop off other students—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—our instructors customize every lesson to your precise needs",
      icon: <LooksOne />,
    },
    {
      head: "Safe and confident for life",
      text: "We teach you the skills you need to be a great driver in the real world - our mission is to eradicate car crashes, and that starts with how we work with you",
      icon: <DirectionsCar />,
    },
  ],
  tx: [
    {
      head: "Certified driving instructors",
      text: "Every instructor is certified and licensed and has completed an intensive training course",
      icon: <CheckCircle />,
    },
    {
      head: "Cutting-edge technology",
      text: "Secure payments and digital lesson reports—we leverage the latest tech to provide an experience you will love",
      icon: <PhoneIphone />,
    },
    {
      head: "Customers first",
      text: "Our instructors don't run errands or make students pick up and drop off other students—your time behind the wheel is your time",
      icon: <TagFaces />,
    },
    {
      head: "Customized lesson plans",
      text: "Our driving lessons aren't for everyone, they're for you—our instructors customize every lesson to your precise needs",
      icon: <LooksOne />,
    },
  ],
}

export const safetyFeatures = [
  {
    head: "Driving instructors you can trust",
    text: "We're one of the only driving schools to do independent background and drug screens on every employee",
    icon: <Face />,
  },
  {
    head: "Marked cars with a second brake",
    text: "Every Coastline car has a second instructor brake, is clearly marked as a driving school vehicle and is inspected by a BAR-certified mechanic",
    icon: <DirectionsCar />,
  },
  {
    head: "Dashcam in every car",
    text: "We record video and audio inside and outside the car for everyone's safety",
    icon: <Videocam />,
  },
]

export const phoneNumber = "(800) 489-1896"
export const collisionNumber = "(760) 437-6170"
export const officeHours = "from 7am to 8pm Pacific Time, 7 days a week"
export const phoneHours = "from 8am to 6pm Pacific Time, Monday through Friday"
export const DEFAULT_TIMEZONE = "America/Los_Angeles"
export const SUPPORT_EMAIL = "hello@coastlineacademy.com"
