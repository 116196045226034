import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
} from "@mui/material"

import { phoneNumber, phoneHours } from "./constants"

interface TextUsDialogProps {
  open: boolean
  onClose: () => void
}

const TextUsDialog: React.FC<TextUsDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">Text Us</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ "& a": { color: "secondary.dark" } }}>
          Text us at{" "}
          <b>
            <a href={`sms:${phoneNumber}`}>{phoneNumber}</a>
          </b>{" "}
          and we&apos;ll get back to you right away. Our typical support hours
          are {phoneHours}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default TextUsDialog
